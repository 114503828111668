<template>
  <div class="center">
    <i v-if="value" class="fa fa-check"></i>
    <i v-if="!value" class="fa fa-close"></i>
  </div>
</template>
<script>
export default {
  name: 'WidgetIsSet',
  props: {
    row: Object,
  },
  data () {
    return {
      value: !!this.row.is_used,
    }
  },
}
</script>
<style lang="scss" scoped>
  .fa {
    font-size: 1.8rem;

    &.fa-check {
      color: green !important;
    }
    &.fa-close {
      color: red !important;
    }
  }
</style>
