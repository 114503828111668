<template>
  <div class="center">
   {{value}}
  </div>
</template>
<script>
  import { parsePhoneNumberFromString } from 'libphonenumber-js'

  export default {
    name: 'ColumnNumber',
    props: {
      row: Object,
    },
    data() {
      return {
      }
    },
    computed: {
      value () {
        const install = this.row;
        let number = install.available_number;
        let result = '';
        if (number)
        {
          const phoneNumber = parsePhoneNumberFromString(number)
          result = phoneNumber.formatNational();
        }
        return result;
      }
    }
  };
</script>
