<template>
  <vuestic-widget :loading="fetching">
    <template slot="header"> 
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">My Installs
          <span class="col availabe-numbers">
            ( Deleted )
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div class="mr-2">
            <b-nav pills>
              <b-nav-item><router-link :to="{name: 'business.installs.active'}">Active</router-link></b-nav-item>
              <b-nav-item><router-link :to="{name: 'business.installs.archived'}">Archived</router-link></b-nav-item>
              <b-nav-item active><router-link :to="{name: 'business.installs.deleted'}">Deleted</router-link></b-nav-item>
            </b-nav>
          </div>
          <router-link
            :to="{name: 'business.installs.create' }"
            class="btn btn-primary btn-sm float-right"
            title="Add Install"
          >
            <span>Add Install <i class="fa fa-plus"></i></span>
          </router-link>
        </div>
      </div>
    </template>

    <datatable v-bind="tableConfig" class="le-datatable" />

  </vuestic-widget>
</template>

<script>
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import ActionsDeleted from './Actions/ActionsDeleted'
  import ColumnNumber from './Columns/Number'
  import ColumnWidgetIsSet from './Columns/WidgetIsSet'
  import CreateInstall from './Actions/CreateInstall'

  export default {
    components: {
      CreateInstall,
    },
    data () {
      return {
        tableConfig: {
          supportBackup: false,
          supportNested: false,
          HeaderSettings: false,
          tblClass: 'table-bordered',
          tblStyle: 'color: #666',
          pageSizeOptions: [10, 25, 50, 100],
          columns: (() => {
            const cols = [
              {
                title: 'ID',
                field: 'id',
                label: 'Install ID',
                sortable: true,
                visible: true,
              },
              {
                title: 'website',
                sortable: true,
                field: 'website',
                thComp: 'FilterTh',
                tdComp: 'PropertyValue',
                tdStyle: { fontStyle: 'normal' },
              },
              {
                title: 'Number',
                sortable: false,
                visible: true,
                tdClass: 'center',
                tdComp: ColumnNumber,
              },
              {
                title: 'Set',
                field: 'is_used',
                sortable: false,
                visible: true,
                tdClass: 'center',
                tdComp: ColumnWidgetIsSet,
              },
            ]
            return cols
          })(),
          data: [],
          total: 0,
          summary: {},
          query: {},
          xprops: {
            eventbus: new Vue(),
          },
        }
      }
    },
    watch: {
      'tableConfig.query': {
          handler: function(newValue, oldValue) {
            this.onQueryChange(newValue)
          },
          deep: true,
        },
        tableData: {
          handler: function(newValue, oldValue) {
            if (newValue != oldValue)
              this.tableConfig.data = newValue;
          },
          deep: true,
        },
        tableTotal: {
          handler: function(newValue, oldValue) {
            if (newValue != oldValue)
              this.tableConfig.total = newValue;
          },
          deep: true,
        }
    },
    mounted() {
      if (!this.didFetch)
      {
        this.loadData();
      }
      this.tableConfig.data = this.tableData;
      this.tableConfig.total = this.tableTotal;

    },
    methods: {
      loadData () {
        this.$store.dispatch('install/fetchAllInstalls')
      },

      onQueryChange (query) {
        const page = Math.floor(query.offset / query.limit) + 1
        const param = {
          page,
          perPage: query.limit
        }
        this.$store.commit('install/filterList', param)
      },
      ...mapMutations('install', {
        filterList: 'filterList'
      })
    },
    computed: {
      user () {
        return this.$store.state.auth.user
      },
      tableData() {
        return this.$store.getters['install/tableData']('deleted');
      },
      tableTotal() {
        return this.$store.getters['install/tableTotal']('deleted');
      },
      ...mapState('install', [
        'fetching',
        'didFetch',
        'processing',
        'page',
        'perPage',
      ]),
    }
  }
</script>

<style scoped lang="scss">
  .no-install-available-span {
    display: block;
    font-size: 16px;
  }

  .agency-link {
    color: #3578c6;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
