<template>
  <a href="javascript: void(0)"
     @click.prevent="callback"
     class="btn btn-primary btn-sm float-right"
     title="Add Install"
  >
    <i class="fa fa-plus" aria-hidden="true"></i> Add Install
  </a>
</template>
<script>
  export default {
    name: 'CreateInstall',
    props: [
      'changeModalRestrict'
    ],
    data() {
      return {

      }
    },
    computed: {

    },
    methods: {
      callback() {
        this.$store.state.app.isLoading = true;
        this.$api.businessCanAddInstall().then(
          ({data}) => {
            if(data.success) {
              
              this.$router.push({name: 'business.installs.create'});
              return;
            }
            this.changeModalRestrict();
            // this.showToast(`Unable to create Install, You already have ${data.active} active from ${data.available} available.`, {duration: 5000});
            this.$store.state.app.isLoading = false;
          },
          () => {
            this.$store.state.app.isLoading = false;
          }
        );
      },
    },
  };
</script>
